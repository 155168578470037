// ## use cycles
//	 @for $i from 1 through 3
//	   .item-#{$i}
//			width: 2em * $i;
//	 @each $s in a,b,c
// 			#{$s}
//				display: none

// ## fonts
// Save fonts to /css/fonts, after that write (eot, woff, ttf):
// +font(book, cytiapro-black-webfont)
// =book
// 	font-family: "book", Arial, sans-serif\

@include font(CoreSansD25Light, CoreSansD25Light);
@mixin fl {
	font-family: "CoreSansD25Light", Arial, sans-serif; }
@include font(CoreSansD45Medium, CoreSansD45Medium);
@mixin fm {
	font-family: "CoreSansD45Medium", Arial, sans-serif;
	-webkit-font-smoothing: antialiased; }
@include font(CoreSansD67CnHeavy, CoreSansD67CnHeavy);
@mixin fh {
	font-family: "CoreSansD67CnHeavy", Arial, sans-serif; }
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&subset=latin,cyrillic);
@mixin fo {
	font-family: "Open Sans", sans-serif; }
@mixin fi {
	font-family: "iconfont";
	font-style: normal;
	font-weight: normal;
	text-rendering: auto;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

$blue: #12aae2;
$bg: #26343B;
$cl: #3c3c3b;

body {
	min-width: 320px;
	@include fm;
	font-size: 14px;
	color: $cl;
	line-height: 1.3; }
button {
	background: none; }
a {
	color: $blue;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $blue; } }

.slick-prev,
.slick-next {
	position: absolute;
	top: 50%;
	margin-top: -20px;
	padding: 5px;
	@include fi;
	font-size: 0;
	color: $blue;
	z-index: 2;
	&:before {
		font-size: 30px; } }
.slick-prev {
	left: 0;
	&:before {
		content: "\EA13"; } }
.slick-next {
	right: 0;
	&:before {
		content: "\EA01"; } }

.wrapper {
	&_reg {
		height: 100%; }
	&_person {
		@include fo; } }

.center {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 20px;
	&_lg {
		max-width: 1440px;
		padding: 0; } }

.title {
	@include fl;
	font-size: 42px;
	color: $blue;
	text-transform: uppercase;
	line-height: 1.1;
	@include r(767) {
		font-size: 30px; }
	&_2 {
		font-size: 28px;
		@include r(767) {
			font-size: 26px; } }
	&_3 {
		font-size: 22px; }
	&_4 {
		font-size: 20px; } }

.btn {
	min-width: 150px;
	height: 38px;
	padding: 0 20px;
	border: 2px solid #12aae2;
	border-radius: 11px;
	font-size: 0;
	overflow: hidden;
	&__icon,
	&__title {
		display: inline-block;
		vertical-align: middle; }
	&__icon {
		margin-right: 15px;
		color: $blue;
		.icon {
			font-size: 20px; } }
	&__title {
		@include fh;
		font-size: 14px;
		text-transform: uppercase; }
	&_white {
		border-color: #fff;
		color: #FFF; }
	&_transparent {
		border: none; } }

.input {
	width: 100%;
	height: 46px;
	border-bottom: 1px solid $blue;
	@include fm;
	font-size: 16px;
	color: $cl;
	text-align: center;
	@include placeholder-color($cl);
	&_left {
		padding: 0 20px;
		text-align: left;
		@include r(767) {
			padding: 0;
			text-align: center; } }
	&_lg {
		@include fl;
		font-size: 21px;
		text-transform: uppercase;
		text-align: left; } }

.textarea {
	display: block;
	width: 100%;
	height: 130px;
	padding: 13px 20px;
	border: 1px solid $blue;
	@include fm;
	font-size: 16px;
	color: $cl;
	@include placeholder-color($cl);
	resize: vertical; }

.field {
	&__wrap {
		position: relative; }
	&__btn {
		position: absolute;
		top: 0;
		right: 0;
		height: 46px;
		font-size: 0;
		color: $blue;
		.icon {
			font-size: 18px; } }
	&__btn + &__input {
		padding-right: 40px; }
	&__help {
		padding-top: 5px;
		font-size: 14px;
		color: #8C8C8C;
		text-align: center;
		&_right {
			text-align: right; } }
	&__select {
		position: relative;
		border-bottom: 1px solid $blue;
		&-head {
			position: relative;
			padding: 0 40px 0 20px; }
		&-title {
			font-size: 16px;
			color: $cl;
			line-height: 45px;
			@include text-overflow; }
		&-arrow {
			position: absolute;
			top: 0;
			right: 0;
			width: 46px;
			height: 45px;
			@include vertical;
			color: $blue;
			.icon {
				font-size: 8px;
				vertical-align: middle; } }
		&-el {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			-webkit-appearance: none;
			cursor: pointer; } } }

.header {
	@include clr;
	&__logo {
		float: left;
		width: 230px;
		height: 80px;
		margin: 15px;
		background: url(../img/logo.svg) no-repeat 0 0;
		@include r(1023) {
			width: 182px;
			height: 63px; }
		@include r(767) {
			width: 125px;
			height: 43px;
			margin: 10px; } }
	&__menu {
		display: none;
		float: right;
		width: 93px;
		height: 93px;
		background: $blue;
		font-size: 0;
		color: #FFF;
		@include r(1023) {
			display: block; }
		@include r(767) {
			width: 63px;
			height: 63px; }
		&.active {
			.icon-menu {
				display: none; }
			.icon-close {
				display: inline-block; } }
		.icon-menu {
			font-size: 20px; }
		.icon-close {
			display: none;
			font-size: 30px; } }
	&__nav {
		float: right;
		font-size: 0;
		@include r(1023) {
			float: none;
			height: 0;
			background: $blue;
			clear: both;
			transition: height .4s;
			overflow: hidden;
			&.active {
				height: 300px; } }
		&-link {
			display: inline-block;
			height: 110px;
			padding: 0 10px;
			font-size: 16px;
			color: #8c8c8c;
			text-transform: uppercase;
			vertical-align: top;
			line-height: 110px;
			transition: color .2s, background .2s;
			@include r(1023) {
				width: 100%;
				height: 50px;
				font-size: 20px;
				color: #FFF;
				text-align: center;
				line-height: 50px; }
			@include r(767) {
				font-size: 16px; }
			&:hover,
			&.active, {
				color: $blue;
				@include r(1023) {
					color: #FFF; } }
			&:last-child {
				margin-left: 45px;
				padding: 0 45px;
				background: $cl;
				color: #fff;
				&:hover {
					background: $blue;
					@include r(1023) {
						background: $cl; } }
				@include r(1280) {
					margin-left: 30px;
					padding: 0 30px; }
				@include r(1023) {
					margin: 0; } } } } }

.main-footer {
	&__wrap {
		padding: 30px 0;
		background: $bg;
		@include r(767) {
			padding-top: 20px; } }
	&__center {
		@include clr; }
	&__logo {
		float: left;
		font-size: 0;
		color: #fff;
		&:hover,
		&:active {
			color: #FFF; }
		@include r(1023) {
			display: table;
			float: none;
			margin: 0 auto; }
		.icon {
			font-size: 62px; } }
	&__nav {
		float: right;
		margin-top: 12px;
		font-size: 0;
		@include r(1023) {
			float: none;
			margin: 0 auto 20px;
			text-align: center; }
		@include r(767) {
			margin: 0 -13px 30px; }
		&-link {
			display: inline-block;
			padding: 7px 13px;
			font-size: 16px;
			text-transform: uppercase;
			color: #FFF;
			&:hover,
			&:focus,
			&:active {
				color: #FFF; }
			&_border {
				height: 38px;
				margin-left: 25px;
				padding: 8px 20px;
				border: 2px solid #12aae2;
				border-radius: 11px;
				font-size: 14px;
				@include r(767) {
					margin: 10px 0 0 0; } } } }
	&__down {
		padding: 10px 0;
		@include r(767) {
			text-align: center; } }
	&__copyright {
		float: left;
		padding: 8px 0;
		font-size: 12px;
		@include r(767) {
			float: none;
			padding-bottom: 30px; } }
	&__links {
		float: right;
		font-size: 0;
		@include r(767) {
			float: none;
			margin-bottom: 20px; }
		&-item {
			display: inline-block;
			padding: 8px 12px;
			font-size: 12px;
			color: $cl;
			@include r(767) {
				width: 100%;
				padding: 8px; } } } }

.main {
	position: relative;
	&__head {
		padding: 100px 0 30px;
		@include r(767) {
			padding: 70px 0 30px; }
		&-center {
			max-width: 480px;
			text-align: center; }
		&-content {
			min-height: 170px; }
		&-title {
			margin-bottom: 10px; }
		&-text {
			margin-bottom: 30px;
			font-size: 18px;
			line-height: 1.1;
			@include r(767) {
				font-size: 16px; } }
		&-btn {
			margin-bottom: 10px; }
		&-msg {
			line-height: 1.2; } }
	&__slider {
		text-align: center;
		&-item {
			position: relative;
			width: 330px;
			height: 320px;
			background: url(../img/bg-main.jpg) no-repeat 0 100%;
			background-size: contain;
			@include r(767) {
				width: 250px;
				height: 240px; }
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: url(../img/bg-main-active.jpg) no-repeat 0 100%;
				background-size: contain;
				opacity: 0;
				transition: opacity .2s; } }
		&-icon {
			position: relative;
			padding-top: 10px;
			font-size: 0;
			color: #CBCDD1;
			transition: transform .2s, color .2s;
			transform: scale(.8);
			z-index: 2;
			.icon {
				font-size: 50px; } } }
	.slick-center {
		&:after {
			opacity: 1; } }
	.slick-center &__slider-icon {
		transform: scale(1);
		color: $blue; } }

.future {
	padding: 30px 0 60px;
	background: url(../img/bg-future.jpg) no-repeat 50% 50%;
	background-size: cover;
	@include r(1023) {
		padding: 30px 0 40px; }
	&__title {
		margin-bottom: 380px;
		text-align: center;
		color: $cl;
		@include r(1023) {
			margin-bottom: 280px; } }
	&__content {
		max-width: 520px; }
	&__text {
		margin-bottom: 20px;
		@include fl;
		font-size: 20px; } }

.capabilities {
	padding: 30px 0 320px;
	background: url(../img/bg-capabilities.png) no-repeat 50% 100% #26343b;
	text-align: center;
	&__title {
		margin-bottom: 20px;
		color: #FFF; }
	&__text {
		max-width: 820px;
		margin: 0 auto 30px;
		@include fl;
		font-size: 20px;
		color: #FFF; }
	&__btn {
		color: #fff; } }

.letsgo {
	position: absolute;
	left: 50%;
	bottom: 55px;
	width: 60px;
	margin-left: -30px;
	// color: #9EA1A0
	color: #FFF;
	text-align: center;
	user-select: none;
	@include r(767) {
		bottom: 15px; }
	&__title {
		margin-bottom: 15px;
		font-size: 12px;
		text-transform: uppercase;
		line-height: 1.1; }
	&__icon {
		font-size: 0;
		.icon {
			font-size: 56px; } } }

.steps {
	position: relative;
	background: $bg;
	text-align: center;
	overflow: hidden;
	&__scene {
		position: absolute;
		top: -20px;
		left: -20px;
		right: -20px;
		bottom: -20px; }
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		&_first {
			background-image: url(../img/bg-steps-1.png);
			z-index: 6; }
		&_second {
			background-image: url(../img/bg-steps-2.png);
			z-index: 4; }
		&_third {
			background-image: url(../img/bg-steps-3.png);
			z-index: 2; } }
	&__center {
		max-width: 1200px;
		@include r(767) {
			padding: 0; } }
	&__row {
		position: relative;
		font-size: 0;
		z-index: 8; }
	&__col {
		display: inline-block;
		width: 33.33%;
		vertical-align: top; }
	&__item {
		padding: 135px 20px 55px;
		vertical-align: top;
		color: #fff;
		@include r(767) {
			padding: 50px 0 40px; } }
	&__item_first &__icon {
		.icon {
			font-size: 80px; } }
	&__item_second &__icon {
		.icon {
			font-size: 100px; } }
	&__item_third &__icon {
		.icon {
			font-size: 84px; } }
	&__item:hover &__icon {
		&:after {
			opacity: 1;
			transform: scale(1.15); } }
	&__icon {
		display: block;
		position: relative;
		width: 130px;
		margin: 0 auto 20px;
		font-size: 0;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: $bg;
			border: 2px solid #0b769d;
			opacity: 0;
			transition: opacity .2s, transform .2s; }
		&-el {
			display: table-cell;
			position: relative;
			width: 130px;
			height: 130px;
			border: 2px solid $blue;
			background: $bg;
			border-radius: 50%;
			vertical-align: middle;
			font-size: 0;
			text-align: center;
			z-index: 2;
			.icon {
				vertical-align: middle; } } }
	&__pic {
		vertical-align: middle; }
	&__title {
		@include fh;
		font-size: 18px;
		text-transform: uppercase; } }

.platform {
	height: 670px;
	padding-top: 150px;
	background: url(../img/bg-platform.jpg) no-repeat 50% 50%;
	background-size: cover;
	@include r(767) {
		height: auto;
		padding: 50px 0; }
	&__center {
		max-width: 900px; }
	&__title {
		margin-bottom: 38px; }
	&__list {
		display: table;
		max-width: 310px;
		margin-bottom: 50px; }
	&__link {
		display: table-row;
		&:hover &-title {
			color: $blue; }
		&:hover &-arrow {
			opacity: 1; }
		&-icon,
		&-title,
		&-arrow {
			display: table-cell;
			padding: 12px 0;
			font-size: 0;
			vertical-align: middle; }
		&-icon {
			.icon-platform-1,
			.icon-platform-2 {
				font-size: 54px; } }
		&-title {
			padding: 0 40px 0 20px;
			@include fh;
			font-size: 16px;
			color: $cl;
			text-transform: uppercase;
			transition: color .2s; }
		&-arrow {
			opacity: 0;
			transition: opacity .2s;
			.icon-arrow-right {
				font-size: 34px; } } } }

.video {
	padding: 50px 0;
	background: url(../img/bg-video.png) no-repeat 50% 50%;
	background-size: cover;
	@include r(767) {
		padding: 30px 0; }
	&__center {
		max-width: 950px; }
	&__wrap {
		position: relative;
		padding-bottom: 60%; }
	&__el {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../img/bg-video-el.jpg) no-repeat 50% 50%;
		background-size: cover; }
	&__play {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 130px;
		height: 130px;
		transform: translate(-50%,-50%);
		background: url(../img/bg-video-play.png) no-repeat 0 0;
		background-size: cover;
		@include r(767) {
			width: 60px;
			height: 60px; } } }

.join {
	height: 800px;
	padding: 80px 0;
	background: url(../img/bg-join.jpg) no-repeat 50% 0;
	background-size: cover;
	text-align: center;
	@include r(1023) {
		height: 600px; }
	@include r(767) {
		height: 450px;
		padding: 50px 0; }
	&__title {
		margin-bottom: 60px;
		color: #FFF; } }

.write {
	padding: 25px 0;
	border-top: 2px solid $blue;
	&__center {
		max-width: 620px; }
	&__row {
		display: table;
		width: 100%; }
	&__col {
		display: table-cell;
		vertical-align: middle;
		@include r(767) {
			display: block;
			text-align: center; }
		&:last-child {
			padding-left: 20px;
			text-align: right;
			@include r(767) {
				padding: 20px 0 0 0;
				text-align: center; } } }
	&__title {
		@include fh;
		font-size: 18px;
		text-transform: uppercase; } }

.slider {
	position: relative;
	z-index: 8;
	&__item {
		height: 720px;
		background-size: cover;
		background-position: 50% 50%;
		@include r(1023) {
			height: 480px; }
		@include r(767) {
			height: 400px; } }
	&__title {
		float: right;
		font-size: 58px;
		color: #FFF;
		@include r(1280) {
			font-size: 42px; }
		@include r(1023) {
			font-size: 38px; }
		@include r(767) {
			float: none;
			padding: 20px;
			background: rgba(#000,.5); }
		&_1 {
			margin: 215px -220px 0 0;
			@include r(1420) {
				margin: 30px -110px 0 0; }
			@include r(1280) {
				margin: 80px 20px 0 0; }
			@include r(1023) {
				margin: 20px 0 0 0; }
			@include r(767) {
				margin: 0 -20px; } }
		&_2 {
			float: left;
			margin: 150px 0 0 20px;
			@include r(767) {
				float: none;
				margin: 0 -20px; } }
		&_3 {
			margin: 375px -110px 0 0;
			@include r(1280) {
				margin: 420px 0 0 0; }
			@include r(1023) {
				margin: 260px 0 0 0; }
			@include r(767) {
				margin: 0 -20px; } }
		&_4 {
			margin: 100px -110px 0 0;
			@include r(1280) {
				margin: 120px 0 0 0; }
			@include r(1023) {
				margin: 70px 190px 0 0; }
			@include r(767) {
				margin: 0 -20px; } }
		&_5 {
			margin: 95px 70px 0 0;
			@include r(1280) {
				margin: 140px 75px 0 0; }
			@include r(1023) {
				margin: 70px 85px 0 0; }
			@include r(767) {
				margin: 0 -20px; } } }
	.slick-dots {
		display: table;
		position: relative;
		margin: -15px auto;
		padding: 0 15px;
		background: #fff;
		border-radius: 30px;
		font-size: 0;
		z-index: 2;
		li {
			display: inline-block;
			position: relative;
			vertical-align: top;
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 14px;
				height: 2px; }
			&:before {
				left: 0;
				right: 0;
				background: #C4C4C4; }
			&:after {
				left: -5px;
				right: -5px;
				background: linear-gradient(to left, rgba($blue,0), rgba($blue,1), rgba($blue,0));
				opacity: 0;
				transition: opacity .2s; }
			&:first-child,
			&:last-child {
				button {
					&:after {
						content: "";
						position: absolute;
						top: 14px;
						width: 12px;
						height: 2px;
						background: #FFF; } } }
			&:first-child {
				button {
					&:after {
						left: -5px; } } }
			&:last-child {
				button {
					&:after {
						right: -5px; } } }
			&.slick-active {
				&:after {
					opacity: 1; }
				button {
					&:before {
						border-color: $blue; } } } }
		button {
			position: relative;
			width: 30px;
			height: 30px;
			vertical-align: top;
			font-size: 0;
			z-index: 4;
			&:before {
				content: "";
				display: inline-block;
				width: 16px;
				height: 16px;
				border: 2px solid #c4c4c4;
				border-radius: 50%;
				background: #fff;
				transition: border-color .2s; } } } }

.about {
	&__item {
		position: relative;
		padding: 100px 0 50px;
		overflow: hidden;
		@include r(767) {
			padding: 50px 0; } }
	&__text {
		position: relative;
		max-width: 450px;
		min-height: 350px;
		@include fl;
		font-size: 20px;
		color: #666;
		z-index: 4;
		@include r(1023) {
			max-width: 400px;
			min-height: 400px; } }
	&__bg {
		position: absolute;
		top: 0;
		left: 50%;
		right: 0;
		height: 100%;
		background-color: #FFF;
		background-repeat: no-repeat;
		background-position: 0 50%;
		@include r(1023) {
			left: 55%; }
		@include r(767) {
			left: 30%;
			opacity: .3; }
		&_1 {
			background-image: url(../img/bg-about-1.jpg); }
		&_2 {
			background-image: url(../img/bg-about-2.jpg); }
		&_3 {
			background-image: url(../img/bg-about-3.jpg); }
		&_4 {
			background-image: url(../img/bg-about-4.jpg); }
		&_5 {
			background-image: url(../img/bg-about-5.jpg); } } }

.benefits {
	padding: 30px 0;
	&__head {
		margin-bottom: 50px;
		text-align: center; }
	&__visual {
		display: table;
		width: 100%;
		margin-bottom: 80px;
		table-layout: fixed;
		@include r(767) {
			margin-bottom: 50px; }
		&-col {
			display: table-cell;
			font-size: 0;
			vertical-align: top; }
		&-col:hover &-move {
			transform: translateX(10%);
			@include r(1023) {
				transform: translateX(0); } }
		&-col:first-child {
			text-align: right; }
		&-col:first-child &-heart {
			margin: 0 10px 0 0;
			@include r(1023) {
				margin: 0 5px 0 0; } }
		&-col:last-child &-bold {
			color: $blue; }
		&-col:first-child:hover &-move {
			transform: translateX(-10%);
			@include r(1023) {
				transform: translateX(0); } }
		&-col:nth-child(2) {
			padding: 0 30px;
			text-align: center;
			@include r(767) {
				padding: 0 15px; } }
		&-phone {
			max-width: 100%; }
		&-text {
			margin-bottom: 35px;
			font-size: 20px;
			line-height: 1.2;
			vertical-align: top;
			text-align: left;
			@include r(767) {
				font-size: 14px; } }
		&-bold {
			font-weight: 700; }
		&-heart {
			height: 35px;
			vertical-align: top;
			@include r(767) {
				height: 15px; } }
		&-move {
			height: 140px;
			margin-left: 10px;
			opacity: .5;
			transition: transform .2s;
			vertical-align: top;
			@include r(1023) {
				margin: 0; }
			@include r(767) {
				height: 50px; } } }
	&__row {
		@include r(767) {
			margin: 0 -20px; } }
	&__icon {
		height: 60px;
		margin-bottom: 15px;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: auto 100%;
		&_1 {
			background-image: url(../img/benefit-1.png); }
		&_2 {
			background-image: url(../img/benefit-2.png); }
		&_3 {
			background-image: url(../img/benefit-3.png); }
		&_4 {
			background-image: url(../img/benefit-4.png); } }
	&__title {
		margin-bottom: 15px; }
	&__text {
		min-height: 80px;
		@include fl;
		font-size: 16px;
		@include r(1023) {
			min-height: 100px; }
		@include r(767) {
			min-height: 0; } }
	.slick-slide {
		box-shadow: 1px 0 0 0 #e1e1e1;
		@include r(767) {
			box-shadow: none; }
		&:first-child {
			>div {
				padding-left: 25px;
				@include r(767) {
					padding: 0; } } }
		>div {
			padding: 25px 45px 30px;
			border-width: 0 1px 1px;
			@include r(767) {
				padding: 0 30px;
				border: none; }
			&:first-child {
				padding-top: 0;
				border-bottom: 1px solid #e1e1e1;
				@include r(767) {
					padding-bottom: 20px;
					border: none; } }
			&:last-child {
				padding-bottom: 0; } } } }

.info {
	padding: 35px 0 50px;
	background: url(../img/bg-info.png) repeat-x 50% 60%;
	text-align: center;
	@include r(767) {
		padding: 35px 0 0; }
	&__center {
		max-width: 520px; }
	&__center.animate &__phone-icons,
	&__center.animate &__text {
		transition: opacity 1s;
		transition-delay: 2s; }
	&__center.animate &__phone-icons_1 {
		opacity: 1; }
	&__center.animate &__phone-icons_2 {
		opacity: 0; }
	&__center.animate &__text_1 {
		opacity: 1; }
	&__center.animate &__text_2 {
		opacity: 0; }
	&__title {
		margin-bottom: 20px; }
	&__content {
		position: relative;
		margin-bottom: 30px;
		font-size: 18px; }
	&__text {
		background: #FFF;
		&_1 {
			opacity: 0; }
		&_2 {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			opacity: 1; } }
	&__phone {
		@include r(767) {
			height: 400px; }
		&-wrap {
			position: relative;
			font-size: 0;
			@include r(767) {
				transform-origin: 50% 0;
				transform: scale(.6); } }
		&-pic {
			max-width: 100%; }
		&-icons {
			position: absolute;
			top: 50%;
			left: 50%;
			&_1 {
				transform: translate(-52%, -57%);
				opacity: 0; }
			&_2 {
				transform: translate(-49.7%, -54%); } } } }

.licenses {
	padding: 50px 0 60px;
	text-align: center;
	&__title {
		margin-bottom: 70px;
		@include r(767) {
			margin-bottom: 30px; } }
	&__list {
		@include r(767) {
			margin: 0 -20px; } }
	&__item {
		padding: 0 30px;
		border: 1px solid #c2c2c2;
		border-width: 0 1px;
		@include r(767) {
			border: none; } }
	&__item_free {
		padding-top: 28px;
		margin-right: -1px;
		@include r(767) {
			margin: 0; } }
	&__item_free &__type {
		color: #8C8C8C; }
	&__label {
		margin-bottom: 10px;
		color: $blue; }
	&__type {
		margin-bottom: 20px;
		@include fl;
		font-size: 58px;
		text-transform: uppercase;
		line-height: 1; }
	&__text {
		min-height: 200px;
		margin-bottom: 20px;
		@include fl;
		font-size: 21px;
		color: #666;
		line-height: 1.2;
		@include r(1023) {
			min-height: 250px; }
		@include r(767) {
			min-height: 0;
			margin-bottom: 30px;
			font-size: 18px; } } }

.popup {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 320px;
	height: 100%;
	padding: 20px;
	background: rgba($cl,.6);
	@include vertical;
	overflow: auto;
	transition: opacity .2s;
	opacity: 0;
	z-index: 999;
	@include r(767) {
		padding: 10px; }
	&.active {
		opacity: 1; }
	&.active &__center {
		transform: scale(1); }
	&__center {
		display: inline-block;
		position: relative;
		width: 100%;
		max-width: 460px;
		vertical-align: middle;
		transform: scale(.8);
		transition: transform .2s; }
	&__wrap {
		padding: 150px 50px 50px;
		background: #fff;
		font-size: 14px;
		text-align: left;
		@include r(767) {
			padding: 70px 20px 40px; } }
	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 0;
		color: $blue;
		.icon {
			font-size: 34px; } }
	&__title {
		margin-bottom: 30px;
		text-align: center; }
	&__form {
		&-fieldset {
			margin-bottom: 45px; }
		&-field {
			margin-bottom: 35px; }
		&-foot {
			text-align: center; }
		&-help {
			max-width: 210px;
			margin: 0 auto;
			padding-top: 10px;
			font-size: 14px;
			color: #8c8c8c; }
		&_feedback &-field {
			margin-bottom: 10px; } }
	&__advantages {
		margin-top: 35px;
		padding-top: 30px;
		border-top: 1px solid #d8d8d8; } }

.advantages {
	&__list {
		margin-bottom: 40px; }
	&__link {
		display: block;
		margin-bottom: 15px;
		color: $cl;
		&:hover &-icon,
		&:hover &-title {
			color: $blue; }
		&:hover &-text {
			color: #8c8c8c; }
		&-icon,
		&-title,
		&-text {
			transition: color .2s; }
		&-icon,
		&-content {
			display: table-cell;
			vertical-align: top; }
		&-icon {
			width: 50px;
			padding: 3px 10px 0 0;
			font-size: 0;
			.icon {
				font-size: 36px; } }
		&-title {
			margin-bottom: 3px;
			font-size: 16px; }
		&-text {
			font-size: 12px;
			color: #D2D2D2; } }
	&__more {
		display: table;
		&-title,
		&-icon {
			display: table-cell;
			vertical-align: middle; }
		&-title {
			padding: 2px 10px 0 0;
			@include fh;
			text-transform: uppercase;
			color: #8c8c8c; }
		&-icon {
			font-size: 0;
			.icon {
				font-size: 14px;
				color: $blue; } } } }

.promo {
	height: 650px;
	padding: 50px 0;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	@include r(1023) {
		height: 480px; }
	@include r(767) {
		height: 400px;
		padding: 30px 0; }
	&_company {
		background-image: url(../img/bg-promo-company.jpg); }
	&_company &__title {
		color: #FFF; }
	&_partners {
		background-image: url(../img/bg-promo-partners.jpg); }
	&_partners &__title {
		padding-top: 410px;
		@include fl;
		font-size: 40px;
		text-align: right;
		color: $cl;
		line-height: 1.1;
		@include r(1023) {
			padding-top: 270px; }
		@include r(767) {
			float: none;
			font-size: 30px;
			padding-top: 180px;
			text-align: center; }
		br {
			@include r(767) {
				display: none; } } } }

.company {
	padding: 45px 0 90px;
	background: url(../img/bg-company.png) no-repeat 50% 50% / cover;
	@include r(767) {
		padding: 30px 0;
		background: #26343B; }
	&__center {
		max-width: 760px; }
	&__logo {
		width: 230px;
		height: 80px;
		margin-bottom: 50px;
		background: url(../img/logo-white.svg) no-repeat 0 0;
		@include r(767) {
			margin-bottom: 20px; } }
	&__text {
		@include fl;
		font-size: 20px;
		color: #fff;
		@include r(767) {
			font-size: 18px; } } }

.goals {
	padding: 40px 0;
	&__head {
		margin-bottom: 50px;
		text-align: center;
		&-text {
			font-size: 20px; } }
	&__row {
		font-size: 0; }
	&__col {
		display: inline-block;
		width: 50%;
		padding: 0 45px 30px;
		vertical-align: top;
		@include r(767) {
			width: 100%;
			padding: 0 0 30px;
			text-align: center; }
		&:first-child {
			border-right: 1px solid #e1e1e1;
			@include r(767) {
				border: none; } }
		&:last-child {
			width: 100%;
			padding: 30px 25%;
			border-top: 1px solid #e1e1e1;
			@include r(767) {
				padding: 30px 0;
				border: none; } } }
	&__icon {
		height: 70px;
		margin-bottom: 15px;
		background-position: 0 100%;
		background-repeat: no-repeat;
		background-size: auto 100%;
		@include r(767) {
			background-position: 50% 100%; }
		&_1 {
			background-image: url(../img/goal-1.png); }
		&_2 {
			background-image: url(../img/goal-2.png);
			background-size: auto 75%; }
		&_3 {
			background-image: url(../img/goal-3.png); } }
	&__title {
		margin-bottom: 15px; }
	&__text {
		@include fl;
		font-size: 16px; } }

.reviews {
	padding: 40px 0 60px;
	&__item {
		padding: 0 30px;
		@include r(1023) {
			padding: 0 10px; } }
	&__row {
		@include clr; }
	&__col {
		float: left;
		width: 55%;
		@include r(767) {
			float: none;
			width: 100%; }
		&:last-child {
			width: 45%;
			padding-left: 40px;
			@include r(767) {
				width: 100%;
				padding: 30px 0 0 0; } } }
	&__content {
		@include fl;
		font-size: 16px;
		strong {
			@include fm;
			font-weight: 400; } }
	&__user {
		&-pic {
			margin-bottom: 20px;
			font-size: 0; }
		&-ava {
			max-width: 100%;
			vertical-align: top; }
		&-title {
			margin-bottom: 5px; }
		&-desc {
			font-size: 16px; } }
	.slick-prev,
	.slick-next {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		padding: 5px;
		@include fi;
		font-size: 0;
		color: $blue;
		z-index: 2;
		&:before {
			font-size: 30px; } }
	.slick-prev {
		left: 50%;
		margin-left: -520px;
		@include r(1080) {
			left: 10px;
			margin-left: 0; }
		@include r(1023) {
			left: 0; } }
	.slick-next {
		right: 50%;
		margin-right: -520px;
		@include r(1080) {
			right: 10px;
			margin-right: 0; }
		@include r(1023) {
			right: 0; } } }

.stats {
	padding: 50px 0 160px;
	background: url(../img/bg-stats.png) no-repeat 50% 50% / cover;
	@include r(767) {
		padding: 30px 0 50px; }
	&__head {
		max-width: 700px;
		margin: 0 auto 80px;
		text-align: center;
		@include r(767) {
			max-width: 100%;
			margin: 0 auto 40px; }
		&-title {
			margin-bottom: 30px; }
		&-text {
			@include fl;
			font-size: 20px; } }
	&__list {
		width: 720px;
		margin: 0 auto;
		padding-left: 40px;
		@include r(767) {
			width: auto;
			margin: 0 -20px;
			padding: 0;
			text-align: center; } }
	&__item {
		padding-right: 40px;
		@include r(767) {
			padding: 0 30px; }
		&-head {
			line-height: 1; }
		&-value,
		&-type {
			display: inline; }
		&-value {
			font-size: 100px;
			color: $blue;
			letter-spacing: -10px; }
		&-type {
			padding-left: 10px;
			font-size: 18px; }
		&-text {
			position: relative;
			padding-bottom: 15px;
			@include fl;
			font-size: 16px;
			line-height: 1.2;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 64px;
				height: 4px;
				background: $blue;
				@include r(767) {
					left: 50%;
					margin-left: -32px; } } } } }

.articles {
	&__item {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		&_1 {
			background-image: url(../img/bg-article-1.jpg); }
		&_2 {
			background-image: url(../img/bg-article-2.jpg); }
		&_3 {
			background-image: url(../img/bg-article-3.jpg); }
		&_4 {
			background-image: url(../img/bg-article-4.jpg); } }
	&__link {
		display: block;
		height: 580px;
		padding: 85px 40px 40px;
		background: rgba(#000,.2);
		color: #FFF;
		@include r(1280) {
			height: 500px;
			padding: 40px; }
		@include r(1023) {
			height: 420px; }
		@include r(767) {
			padding: 30px; } }
	&__link:hover {
		background: rgba($blue,.6); }
	&__link:hover &__text {
		opacity: 1; }
	&__head {
		margin-bottom: 30px; }
	&__title,
	&__arrow {
		display: table-cell;
		vertical-align: middle; }
	&__title {
		width: 245px;
		padding-right: 20px;
		line-height: 1.0;
		font-size: 28px; }
	&__arrow {
		font-size: 0;
		.icon {
			font-size: 40px; } }
	&__text {
		@include fl;
		font-size: 18px;
		opacity: 0;
		transition: opacity .2s;
		@include r(1023) {
			opacity: 1; } }
	.slick-prev,
	.slick-next {
		color: #FFF;
		&:before {
			font-size: 40px;
			@include r(767) {
				font-size: 30px; } } } }

.plan {
	padding: 40px 0 60px;
	@include r(767) {
		padding: 30px 0 20px; }
	&__head {
		margin-bottom: 50px;
		text-align: center;
		@include r(767) {
			margin-bottom: 10px; } }
	&__row {
		display: table;
		width: 100%;
		border-bottom: 1px solid #e1e1e1;
		table-layout: fixed;
		@include r(767) {
			border: none;
			text-align: center; } }
	&__row:last-child {
		border: none;
		padding: 0 15%;
		text-align: center;
		@include r(767) {
			padding: 0; } }
	&__row:last-child &__col {
		@include r(767) {
			border: none; } }
	&__col {
		display: table-cell;
		padding: 25px;
		border: 1px solid #e1e1e1;
		border-width: 0 1px 0 0;
		@include r(767) {
			display: block;
			padding: 20px 0;
			border-width: 0 0 1px; }
		&:last-child {
			border-right: none; } }
	&__title {
		margin-bottom: 10px; }
	&__text {
		font-size: 16px; } }

.contacts {
	padding: 40px 0;
	&__head {
		margin-bottom: 40px;
		text-align: center;
		@include r(767) {
			margin-bottom: 20px; } }
	&__l {
		margin: 0 -15px;
		@include clr; }
	&__col {
		float: left;
		width: 40%;
		padding: 0 15px;
		@include r(1023) {
			width: 100%; }
		&:first-child {
			width: 60%;
			@include r(1023) {
				width: 100%; } } }
	&__row {
		margin-bottom: 20px;
		@include clr; }
	&__row_inverse &__cell {
		&:first-child {
			float: right;
			width: 50%;
			text-align: right;
			@include r(1023) {
				float: left;
				width: 85px;
				text-align: left; }
			@include r(767) {
				width: 50px; } }
		&:last-child {
			float: left;
			width: 50%;
			@include r(1023) {
				float: none;
				width: auto; } } }
	&__cell {
		overflow: hidden;
		&:first-child {
			float: left;
			width: 85px;
			@include r(767) {
				width: 50px; } } }
	&__icon {
		font-size: 0;
		color: $blue;
		.icon {
			font-size: 48px;
			@include r(767) {
				font-size: 32px; } } }
	&__title {
		margin-bottom: 5px;
		padding-top: 15px;
		@include fh;
		font-size: 18px;
		text-transform: uppercase;
		@include r(767) {
			padding-top: 5px; } }
	&__wrap {
		font-size: 0; }
	&__item {
		display: inline-block;
		width: 50%;
		padding-right: 30px;
		vertical-align: top;
		@include r(767) {
			width: 100%; }
		&-title {
			margin-bottom: 5px;
			font-size: 16px; }
		&-content {
			@include fl;
			font-size: 24px;
			font-weight: 300;
			text-transform: uppercase;
			color: $blue;
			@include r(767) {
				font-size: 18px; } }
		&_lg {
			width: 100%; } }
	&__form {
		max-width: 400px;
		margin: 0 auto; }
	&__field {
		margin-bottom: 15px; } }

.map {
	height: 300px; }

.reg {
	min-height: calc(100% - 173px);
	padding: 100px 0 200px;
	background: url(../img/bg-reg.png) no-repeat 50% 50% / cover;
	&__center {
		max-width: 600px; }
	&__logo {
		width: 105px;
		height: 105px;
		margin: 0 auto 45px;
		background: url(../img/logo-vert.svg) no-repeat 0 0; }
	&__title {
		margin-bottom: 5px;
		text-align: center; }
	&__steps {
		margin-bottom: 45px;
		text-align: center;
		&-head {
			margin-bottom: 15px; }
		&-count,
		&-title {
			display: inline; }
		&-count {
			text-transform: uppercase;
			font-weight: 700; }
		&-title {
			color: #8C8C8C; }
		&-nav {
			position: relative;
			width: 98px;
			height: 8px;
			margin: 0 auto 6px;
			border-bottom: 2px solid #d3d3d3;
			white-space: nowrap;
			font-size: 0; }
		&-item {
			display: inline-block;
			position: relative;
			margin-left: 14px;
			vertical-align: top;
			&:first-child {
				margin-left: 0;
				&.active {
					&:after {
						left: 0; } } }
			&:last-child {
				&.active {
					&:after {
						right: 0; } } }
			&.active {
				&:before {
					border-color: $blue; }
				&:after {
					opacity: 1; } }
			&:before {
				content: "";
				display: block;
				position: relative;
				width: 14px;
				height: 14px;
				background: #fff;
				border: 2px solid #d3d3d3;
				border-radius: 50%;
				transition: border-color .2s;
				z-index: 2; }
			&:after {
				content: "";
				position: absolute;
				top: 6px;
				left: -14px;
				right: -14px;
				height: 2px;
				background: linear-gradient(to left, rgba($blue,0), rgba($blue,1), rgba($blue,0));
				opacity: 0;
				transition: opacity .2s; } } }
	&__form {
		max-width: 440px;
		margin: 0 auto;
		&-field {
			margin-bottom: 25px;
			@include r(767) {
				margin: 0; } }
		&-foot {
			margin-top: 40px;
			font-size: 0;
			text-align: center; }
		&-cell {
			display: inline-block;
			margin: 0 10px;
			vertical-align: top; }
		&-help {
			padding-top: 5px;
			font-size: 14px;
			color: #8C8C8C; }
		&_wide {
			max-width: 100%; } }
	&__finish {
		max-width: 400px;
		margin: 0 auto;
		text-align: center;
		&-title {
			margin-bottom: 30px;
			@include fl;
			font-size: 20px; } } }

.pay {
	&__row {
		@include clr; }
	&__col {
		float: right;
		width: 177px;
		@include r(767) {
			float: none;
			width: 100%; }
		&:first-child {
			float: left;
			width: 320px;
			@include r(767) {
				float: none;
				width: 100%; } } }
	&__field {
		margin-bottom: 15px;
		@include r(767) {
			margin: 0; } }
	&__card {
		margin: 45px 0 27px;
		@include r(767) {
			margin: 30px auto; } }
	&__cvv {
		font-size: 0;
		@include r(767) {
			margin-bottom: 30px;
			text-align: center; }
		&-label,
		&-wrap {
			display: inline-block;
			vertical-align: middle; }
		&-label {
			margin-right: 20px;
			font-size: 16px;
			text-transform: uppercase; }
		&-input {
			width: 90px;
			height: 42px;
			border: 1px solid $blue; } }
	&__foot {
		display: table;
		width: 100%;
		&-cell {
			display: table-cell;
			padding: 9px 20px;
			border-bottom: 1px solid $blue;
			font-size: 16px;
			@include r(767) {
				display: block;
				text-align: center; }
			&:last-child {
				padding-right: 0;
				text-align: right;
				@include r(767) {
					text-align: center; } } }
		&-sum {
			font-size: 22px;
			font-weight: 700; } } }

.card {
	width: 177px;
	&__front,
	&__back {
		width: 163px;
		height: 103px;
		padding-top: 20px;
		border: 1px solid $blue;
		border-radius: 11px;
		background: #FFF; }
	&__back {
		margin: -91px 0 0 14px;
		@include fh;
		font-size: 14px;
		text-align: right;
		text-transform: uppercase; }
	&__line {
		height: 22px;
		padding-right: 12px;
		background: $blue;
		color: #FFF;
		line-height: 22px; }
	&__cvv {
		padding-right: 12px;
		text-transform: uppercase;
		text-align: right;
		color: #8c8c8c;
		line-height: 22px; }
	&__type {
		padding: 10px;
		text-align: left;
		&-pic {
			max-width: 50px;
			max-height: 25px; } }
	&_sm {
		width: 132px; }
	&_sm &__front,
	&_sm &__back {
		width: 122px;
		height: 77px;
		padding-top: 15px; }
	&_sm &__back {
		margin: -67px 0 0 10px; }
	&_sm &__line {
		height: 16px;
		line-height: 16px;
		font-size: 12px; } }


.user {
	@include r(767) {
		text-align: center; }
	&__row {
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px dotted $blue;
		@include clr; }
	&__col {
		float: left;
		width: 360px;
		@include r(767) {
			float: none;
			width: 100%; }
		&:first-child {
			width: 200px;
			padding-right: 20px;
			@include r(767) {
				width: 100%;
				margin-bottom: 20px;
				padding: 0; } } }
	&__ava {
		width: 126px;
		height: 126px;
		@include r(767) {
			margin: 0 auto; }
		&-pic {
			max-width: 100%;
			border-radius: 50%; } }
	&__card {
		@include r(767) {
			margin: 0 auto; } }
	&__title {
		margin-bottom: 5px;
		text-transform: none; }
	&__referal,
	&__number {
		margin-bottom: 20px;
		color: #8c8c8c; }
	&__contact {
		font-size: 18px;
		&-type,
		&-value {
			display: inline; }
		&-type {
			margin-right: 5px;
			@include fl; } }
	&__data {
		&-cell {
			display: table-cell;
			vertical-align: top;
			&:first-child {
				padding-right: 40px; } }
		&-type {
			@include fl;
			font-size: 18px; }
		&-value {
			font-size: 18px; } } }

.main-tabs {
	background: #FFF;
	text-align: center;
	@include r(1200) {
		text-align: left; }
	@include r(767) {
		text-align: center; }
	&__link {
		display: inline-block;
		height: 85px;
		margin: 0 25px;
		padding: 0 15px;
		border-bottom: 5px solid transparent;
		font-size: 14px;
		font-weight: 600;
		color: #000;
		vertical-align: top;
		line-height: 80px;
		@include r(1200) {
			margin: 0 15px 0 0;
			padding: 0 10px; }
		@include r(1023) {
			padding: 0;
			height: 60px;
			line-height: 60px; }
		@include r(767) {
			height: 40px;
			margin: 0 5px;
			border-width: 0 0 2px 0;
			line-height: 40px; }
		&:last-child {
			float: right;
			margin: 0;
			padding: 0;
			font-size: 12px;
			color: #B2B2B2;
			&:hover {
				color: $blue; } }
		&.active {
			border-color: $blue;
			color: $blue; } } }

.main-container {
	padding: 40px 0 90px;
	&_team {
		padding-top: 0; } }

.main-head {
	margin-bottom: 35px;
	&__title {
		margin-bottom: 5px;
		font-size: 30px;
		color: $blue; }
	&__text {
		font-size: 14px; } }

.widget {
	margin-bottom: 20px;
	background: #FFF;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 4px 0 rgba(0, 0, 0, 0.08);
	font-weight: 600;
	&__wrap {
		display: table;
		width: 100%;
		padding: 10px 20px 10px 0;
		@include r(767) {
			padding-right: 10px; } }
	&__cell {
		display: table-cell;
		height: 120px;
		padding-left: 20px;
		vertical-align: middle;
		@include r(767) {
			height: auto;
			padding-left: 10px; } }
	&__icon {
		font-size: 0;
		color: $blue;
		.icon {
			font-size: 80px;
			@include r(767) {
				font-size: 40px; } }
		.icon-widget-3 {
			font-size: 66px;
			@include r(767) {
				font-size: 33px; } } }
	&__title {
		font-size: 16px;
		@include r(767) {
			font-size: 14px; } }
	&__value {
		font-size: 40px;
		line-height: 1;
		@include r(767) {
			font-size: 32px; }
		&_sm {
			font-size: 26px; } }
	&__type {
		font-size: 14px;
		color: #8C8C8C; }
	&__sub {
		margin-left: 5px;
		font-size: 12px;
		color: #8C8C8C; }
	&__links {
		padding-top: 10px;
		font-size: 12px;
		&-item {
			display: table-cell;
			padding-right: 20px; } }
	&__chart {
		padding-top: 40px; }
	&_lg {
		padding: 10px 0; }
	&_lg &__cell {
		height: 340px;
		vertical-align: top; } }

.top {
	height: 55px;
	background: #3C3C3B;
	&__center {
		@include clr; }
	&__logo {
		float: left;
		width: 105px;
		height: 32px;
		margin: 10px 0;
		background: url(../img/logo-white.svg) no-repeat 0 0; }
	&__right {
		float: right; }
	&__notify,
	&__notify-item,
	&__user,
	&__user-pic,
	&__user-name,
	&__out {
		display: table-cell;
		height: 55px;
		font-size: 0;
		vertical-align: middle; }
	&__notify {
		padding: 0 12px;
		@include r(767) {
			padding: 0; }
		&-item {
			padding: 0 12px;
			color: #FFF;
			@include r(767) {
				padding: 0 5px; }
			.icon-notify {
				font-size: 28px;
				@include r(767) {
					font-size: 24px; } }
			.icon-message {
				font-size: 20px;
				@include r(767) {
					font-size: 16px; } } } }
	&__user {
		position: relative;
		padding: 0 20px;
		color: #FFF;
		@include r(767) {
			padding: 0 5px; }
		&:before {
			content: '';
			position: absolute;
			top: 10px;
			left: 0;
			bottom: 10px;
			width: 1px;
			background: #FFF;
			@include r(767) {
				display: none; } }
		&-pic {
			width: 40px;
			height: 40px; }
		&-ava {
			border-radius: 50%; }
		&-name {
			padding-left: 20px;
			font-size: 14px;
			@include r(767) {
				display: none;
				padding-left: 10px; } } }
	&__out {
		padding-left: 5px;
		font-size: 0;
		color: #fff;
		.icon {
			font-size: 24px;
			@include r(767) {
				font-size: 20px; } } } }

.materials {
	margin: 0 -10px;
	font-size: 0;
	@include r(767) {
		margin: 0 -5px; }
	&__item {
		margin: 0 10px 20px;
		@include r(767) {
			margin: 0 5px 10px; } } }

.material {
	display: inline-block;
	position: relative;
	width: 180px;
	min-height: 220px;
	padding: 35px 10px 10px;
	background: white;
	border: 1px solid #dae0e4;
	border-radius: 5px;
	text-align: center;
	vertical-align: top;
	@include r(767) {
		width: 140px; }
	&__type {
		margin-bottom: 30px;
		font-size: 0; }
	&__pic {
		max-height: 90px;
		@include r(767) {
			max-height: 60px; } }
	&__title {
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 600;
		color: $cl; }
	&__date {
		font-size: 12px;
		color: #8C8C8C; }
	&_video {
		width: 380px;
		background: url(../img/bg-video.jpg) no-repeat 0 0 / cover;
		@include r(767) {
			width: 290px; } }
	&_video &__type {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0;
		transform: translate(-50%,-50%);
		color: #fff;
		.icon {
			font-size: 34px; } }
	&_video &__title {
		position: absolute;
		left: 25px;
		bottom: 15px;
		right: 90px;
		margin: 0;
		color: #FFF;
		text-align: left; }
	&_video &__date {
		position: absolute;
		right: 25px;
		bottom: 15px;
		color: #FFF; } }

.team {
	&__head {
		margin-bottom: 15px;
		border-top: 3px solid #ebeff2;
		background: #fff;
		&-btn {
			position: relative;
			float: right;
			min-width: 120px;
			height: 47px;
			padding: 0 10px 0 30px;
			font-size: 14px;
			font-weight: 600;
			color: $blue;
			&:before {
				content: '';
				position: absolute;
				top: 5px;
				left: 0;
				bottom: 5px;
				width: 1px;
				background: #a0a0a0; }
			.icon {
				margin-left: 20px;
				font-size: 10px; } }
		&-field {
			overflow: hidden; }
		&-input {
			width: 100%;
			height: 47px;
			font-size: 18px;
			color: $blue;
			@include placeholder-color($blue); } }
	&__container {
		@include clr; } }







