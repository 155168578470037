@font-face {
    font-family: "iconfont";
    src: url('../fonts/iconfont.eot');
    src: url('../fonts/iconfont.eot?#iefix') format('eot'),
         url('../fonts/iconfont.woff') format('woff'),
         url('../fonts/iconfont.woff2') format('woff2'),
         url('../fonts/iconfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$icon-arrow-right: "\EA01";
$icon-benefit-1: "\EA02";
$icon-benefit-2: "\EA03";
$icon-benefit-3: "\EA04";
$icon-benefit-4: "\EA05";
$icon-logo: "\EA06";
$icon-mail: "\EA07";
$icon-platform-1: "\EA08";
$icon-platform-2: "\EA09";
$icon-scroll-down: "\EA0A";
$icon-sl-1: "\EA0B";
$icon-sl-2: "\EA0C";
$icon-sl-3: "\EA0D";
$icon-sl-4: "\EA0E";
$icon-sl-5: "\EA0F";
$icon-steps-1: "\EA10";
$icon-steps-2: "\EA11";
$icon-steps-3: "\EA12";
$icon-arrow-left: "\EA13";
$icon-close: "\EA14";
$icon-menu: "\EA15";
$icon-finger: "\EA16";
$icon-arrows: "\EA17";
$icon-eye: "\EA18";
$icon-email: "\EA19";
$icon-feedback: "\EA1A";
$icon-map: "\EA1B";
$icon-phone: "\EA1C";
$icon-select-arrow: "\EA1D";
$icon-message: "\EA1E";
$icon-notify: "\EA1F";
$icon-out: "\EA20";
$icon-widget-1: "\EA21";
$icon-widget-2: "\EA22";
$icon-widget-3: "\EA23";
$icon-arrow-down: "\EA24";
$icon-arrow-toggle: "\EA25";
$icon-play: "\EA26";


%icon {
    font-family: "iconfont";
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon {
    @extend %icon;
    display: inline-block;
}

.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-benefit-1:before { content: $icon-benefit-1; }
.icon-benefit-2:before { content: $icon-benefit-2; }
.icon-benefit-3:before { content: $icon-benefit-3; }
.icon-benefit-4:before { content: $icon-benefit-4; }
.icon-logo:before { content: $icon-logo; }
.icon-mail:before { content: $icon-mail; }
.icon-platform-1:before { content: $icon-platform-1; }
.icon-platform-2:before { content: $icon-platform-2; }
.icon-scroll-down:before { content: $icon-scroll-down; }
.icon-sl-1:before { content: $icon-sl-1; }
.icon-sl-2:before { content: $icon-sl-2; }
.icon-sl-3:before { content: $icon-sl-3; }
.icon-sl-4:before { content: $icon-sl-4; }
.icon-sl-5:before { content: $icon-sl-5; }
.icon-steps-1:before { content: $icon-steps-1; }
.icon-steps-2:before { content: $icon-steps-2; }
.icon-steps-3:before { content: $icon-steps-3; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-close:before { content: $icon-close; }
.icon-menu:before { content: $icon-menu; }
.icon-finger:before { content: $icon-finger; }
.icon-arrows:before { content: $icon-arrows; }
.icon-eye:before { content: $icon-eye; }
.icon-email:before { content: $icon-email; }
.icon-feedback:before { content: $icon-feedback; }
.icon-map:before { content: $icon-map; }
.icon-phone:before { content: $icon-phone; }
.icon-select-arrow:before { content: $icon-select-arrow; }
.icon-message:before { content: $icon-message; }
.icon-notify:before { content: $icon-notify; }
.icon-out:before { content: $icon-out; }
.icon-widget-1:before { content: $icon-widget-1; }
.icon-widget-2:before { content: $icon-widget-2; }
.icon-widget-3:before { content: $icon-widget-3; }
.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-toggle:before { content: $icon-arrow-toggle; }
.icon-play:before { content: $icon-play; }
